import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomFooter, GridContainer, StyledNextButton } from "./ProductGrid";
import {
  Button,
  Card,
  Form,
  Image,
  Input,
  InputNumber,
  Select,
  message,
} from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { ButtonGroup } from "@mui/material";
import PushPinOutlined from "../assets/images/pin.png";
import ShopOutlined from "../assets/images/shop.png";
import { Inventory } from "../redux/reducers/InventoryReducer";
import { Store } from "../redux/reducers/StoreReducer";
import { useAppSelector } from "../redux/hooks";
import { AppState } from "../redux/reducers/AppReducer";
import {
  ProductTemplate,
  ProductTemplateState,
} from "../redux/reducers/ProductTemplateReducer";
import Colors from "../constants/Colors";
import {
  AddMoreButton,
  Container,
  ItemNextButton,
  LocationSelector,
  MobileViewHeader,
  PayCard,
  PayHeader,
  PaymentTab,
  PriceInput,
  ProductItemCard,
  ProductItemCardMobile,
  ProductItemList,
} from "../styles/BuyAndSellStepTwo";

export const StepTwo = ({
  inventoriesToAdd,
  indexItemToEdit,
  setIndexItemToEdit,
  store,
  setInventoriesToAdd,
  newInventory,
  setNewInventory,
  candidateQty,
  candidatePrice,
  setCandidateQty,
  setCandidatePrice,
  setCurrentStep,
  methodDelivery,
  setMethodDelivery,
  location,
  setShowLocationModal,
  dataLoading,
  productTemplates,
  consignerData,
  setConsignerData,
  currentStep,
  handleCompleteProcess,
  dataSubmitting,
}: {
  inventoriesToAdd: Inventory[];
  indexItemToEdit: number;
  setIndexItemToEdit: any;
  store: Store;
  setInventoriesToAdd: any;
  newInventory: Inventory[];
  setNewInventory: any;
  candidateQty: number;
  candidatePrice: number;
  setCandidateQty: any;
  setCandidatePrice: any;
  setCurrentStep: any;
  methodDelivery: number;
  setMethodDelivery: any;
  location: string;
  setShowLocationModal: any;
  dataLoading: boolean;
  productTemplates?: ProductTemplate[];
  consignerData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    zipCode: string;
  };
  setConsignerData: any;
  currentStep: number;
  handleCompleteProcess: any;
  dataSubmitting: boolean;
}) => {
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [stepTwoPage, setStepTwoPage] = useState<number>(1);

  useEffect(() => {
    // console.log(productTemplates);
  }, [isMobile]);

  useEffect(() => {
    setIndexItemToEdit(0);
  }, []);

  if (isMobile)
    return (
      <Container>
        {currentStep === 1 ? (
          <div>
            {stepTwoPage !== 3 ? (
              <div>
                <MobileViewHeader>
                  <PayHeader>
                    <b>Request Price</b>
                    <label>Input your price</label>
                  </PayHeader>
                  <AddMoreButton
                    type="text"
                    onClick={() => setCurrentStep((prev) => prev - 1)}
                  >
                    + Add Products
                  </AddMoreButton>
                </MobileViewHeader>
                {inventoriesToAdd
                  .slice(0, 1)
                  .map((inventory: Inventory, key: number) => (
                    <ProductItemCardMobile
                      key={key}
                      bordered={false}
                      style={{
                        border:
                          key === indexItemToEdit && stepTwoPage === 1
                            ? "2px solid #685FF9"
                            : "1px solid #DDDDDD99",
                        borderRadius: "10px",
                        background:
                          key === indexItemToEdit && stepTwoPage === 1
                            ? "white"
                            : `${Colors.SMOKE_GREY}`,
                      }}
                      onClick={() => setIndexItemToEdit(key)}
                      className={
                        key === indexItemToEdit && stepTwoPage === 1
                          ? "card-selected"
                          : "card"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          position: "relative",
                          gap: "10px",
                        }}
                      >
                        <Image src={inventory?.product?.image} />
                        <div style={{ flex: 3 }}>
                          <label>MODEL</label>
                          <b>{inventory?.product?.title}</b>
                        </div>
                        <div style={{ flex: 0, marginBottom: "auto" }}>
                          <Button
                            type="text"
                            onClick={() =>
                              setInventoriesToAdd([
                                ...inventoriesToAdd.filter(
                                  (_, idx) => idx !== key
                                ),
                              ])
                            }
                            danger
                            icon={<CloseOutlined />}
                          />
                        </div>
                      </div>
                      <div className="product-properties">
                        <div>
                          <label>SKU</label>
                          <b style={{ wordBreak: "break-all" }}>
                            {inventory?.product?.sku}&nbsp;
                          </b>
                        </div>
                        <div>
                          <label>Size</label>
                          <b>{inventory?.option1Value}</b>
                        </div>
                        <div>
                          <label>Price</label>
                          <b>
                            {getSymbolFromCurrency(store.currency)}
                            {inventory?.price}
                          </b>
                        </div>
                        <div>
                          <label>Quantity</label>
                          <b>{inventory?.quantity}</b>
                        </div>
                      </div>
                    </ProductItemCardMobile>
                  ))}
                {stepTwoPage === 1 && (
                  <div>
                    <PriceInput>
                      <div>
                        <h3>Quantity</h3>
                        <div>
                          <InputNumber
                            prefix={null}
                            readOnly={indexItemToEdit < 0}
                            // bordered={false}
                            min={1}
                            value={candidateQty}
                            onChange={(value) => setCandidateQty(value ?? 0)}
                          />
                        </div>
                      </div>
                      <div>
                        <h3>Requested Price</h3>
                        <div className="price-input" style={{ flex: 1 }}>
                          <Input
                            readOnly={indexItemToEdit < 0}
                            bordered={false}
                            prefix={
                              getSymbolFromCurrency(store.currency) || "$"
                            }
                            value={candidatePrice}
                            onChange={(value) =>
                              setCandidatePrice(+value.target.value)
                            }
                          />
                        </div>
                      </div>
                      <ItemNextButton
                        style={{
                          position: "relative",
                          left: "initial",
                          bottom: "initial",
                          transform: "none",
                          maxWidth: "50%",
                          alignSelf: "flex-end",
                        }}
                        // disabled={ /*  || (newInventory && Number(newInventory.price) === 0) */}
                        onClick={() => {
                          if (
                            indexItemToEdit !== inventoriesToAdd.length - 1 ||
                            inventoriesToAdd.some(
                              (inventory) => Number(inventory.price) === 0
                            )
                          ) {
                            setIndexItemToEdit((prev) => prev + 1);
                          } else {
                            setStepTwoPage((prev) => prev + 1);
                          }
                        }}
                      >
                        Next
                      </ItemNextButton>
                    </PriceInput>
                  </div>
                )}
                {inventoriesToAdd
                  .slice(1)
                  .map((inventory: Inventory, key: number) => (
                    <ProductItemCardMobile
                      key={key}
                      bordered={false}
                      style={{
                        border:
                          key + 1 === indexItemToEdit && stepTwoPage === 1
                            ? "2px solid #685FF9"
                            : "1px solid #DDDDDD99",
                        borderRadius: "10px",
                        background:
                          key + 1 === indexItemToEdit && stepTwoPage === 1
                            ? "white"
                            : `${Colors.SMOKE_GREY}`,
                      }}
                      onClick={() => setIndexItemToEdit(key + 1)}
                      className={
                        key === indexItemToEdit && stepTwoPage === 1
                          ? "card-selected"
                          : "card"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          position: "relative",
                          gap: "10px",
                        }}
                      >
                        <Image src={inventory?.product?.image} />
                        <div style={{ flex: 3 }}>
                          <label>MODEL</label>
                          <b>{inventory?.product?.title}</b>
                        </div>
                        <div style={{ flex: 0, marginBottom: "auto" }}>
                          <Button
                            type="text"
                            onClick={() =>
                              setInventoriesToAdd([
                                ...inventoriesToAdd.filter(
                                  (_, idx) => idx !== key
                                ),
                              ])
                            }
                            danger
                            icon={<CloseOutlined />}
                          />
                        </div>
                      </div>
                      <div className="product-properties">
                        <div>
                          <label>SKU</label>
                          <b style={{ wordBreak: "break-all" }}>
                            {inventory?.product?.sku}&nbsp;
                          </b>
                        </div>
                        <div>
                          <label>Size</label>
                          <b>{inventory?.option1Value}</b>
                        </div>
                        <div>
                          <label>Price</label>
                          <b>
                            {getSymbolFromCurrency(store.currency)}
                            {inventory?.price}
                          </b>
                        </div>
                        <div>
                          <label>Quantity</label>
                          <b>{inventory?.quantity}</b>
                        </div>
                      </div>
                    </ProductItemCardMobile>
                  ))}
              </div>
            ) : (
              <LocationSelector>
                <PayHeader>
                  <b>Shipping</b>
                  <label>Shipping method, please select one.</label>
                </PayHeader>
                <div>
                  <span
                  // style={{ display: "flex", flexWrap: "nowrap", gap: 16 }}
                  >
                    <PayCard
                      className={`${methodDelivery === 1 ? "selected" : ""}`}
                      onClick={() => setMethodDelivery(1)}
                    >
                      <Image preview={false} src={PushPinOutlined} />
                      <div>
                        <b>Drop Off</b>
                        <label onClick={() => setShowLocationModal(true)}>
                          {location
                            ? location
                            : "You need to select the drop off location."}
                        </label>
                        <Button
                          disabled={dataLoading}
                          onClick={() => setShowLocationModal(true)}
                        >
                          Select
                        </Button>
                      </div>
                    </PayCard>
                    <PayCard
                      // className={`${methodDelivery === 2 ? "selected" : ""}`}
                      style={{ cursor: "not-allowed" }}
                      onClick={() => setMethodDelivery(1)}
                    >
                      {" "}
                      {/* should be 2 */}
                      <Image preview={false} src={ShopOutlined} />
                      <div>
                        <b>Ship to Store</b>
                        <label>
                          {location
                            ? location
                            : " You have 34 stores, select the closest one to you."}
                        </label>
                        <Button>Select</Button>
                      </div>
                    </PayCard>
                  </span>
                </div>
              </LocationSelector>
            )}
            <ButtonGroup
              style={{
                display: "flex",
                // width: "100%",
                // justifyContent: "right",
                alignItems: "center",
                gap: 12,
                width: "100%",
                position: "fixed",
                bottom: 0,
                left: 0,
                padding: "16px",
                background: "white",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
              }}
            >
              <Button
                style={{
                  // alignSelf: "stretch",
                  height: 45,
                  // width: 203,
                  // maxWidth: "50%",
                  padding: "10px 40px",
                  // flex: 1,
                  borderRadius: 8,
                  background: "var(--Grey---Button-Fill, #F4F4F4)",
                  color: "#292D32",
                  fontWeight: 600,
                  width: "30%",
                }}
                type="default"
                onClick={() => {
                  if (stepTwoPage !== 1) {
                    setStepTwoPage((prev) => prev - 1);
                  } else {
                    setCurrentStep((prev) => prev - 1);
                  }
                }}
              >
                <ArrowLeftOutlined /> Back
              </Button>
              <StyledNextButton
                style={{
                  position: "relative",
                  left: "initial",
                  bottom: "initial",
                  transform: "none",
                  height: 45,
                  width: "67%",
                }}
                // disabled={ /*  || (newInventory && Number(newInventory.price) === 0) */}
                onClick={() => {
                  if (
                    indexItemToEdit !== inventoriesToAdd.length - 1 ||
                    inventoriesToAdd.some(
                      (inventory) => Number(inventory.price) === 0
                    )
                  ) {
                    setIndexItemToEdit((prev) => prev + 1);
                  } else {
                    if (stepTwoPage === 3 && (location === "" || !location)) {
                      message.error("Drop Off location is required.");
                    } else if (
                      stepTwoPage === 3 &&
                      location &&
                      location !== ""
                    ) {
                      setCurrentStep((prev) => prev + 1);
                    } else if (stepTwoPage !== 3) {
                      setStepTwoPage((prev) => prev + 1);
                    }
                  }
                }}
              >
                Next
              </StyledNextButton>
            </ButtonGroup>
          </div>
        ) : (
          <div style={{ paddingBottom: 150 }}>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "30px 0",
              }}
            >
              <b>Contact</b>
              <label>Fill Your Information</label>
            </span>
            <Form>
              <span>
                <label>First Name</label>
                <Input
                  required
                  value={consignerData.firstName}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      firstName: value.target.value,
                    })
                  }
                />
              </span>
              <span>
                <label>Last Name</label>
                <Input
                  required
                  value={consignerData.lastName}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      lastName: value.target.value,
                    })
                  }
                />
              </span>
              <span>
                <label>Email</label>
                <Input
                  required
                  value={consignerData.email}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      email: value.target.value,
                    })
                  }
                />
              </span>
              <span>
                <label>Phone Number</label>
                <Input
                  required
                  value={consignerData.phone}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      phone: value.target.value,
                    })
                  }
                />
              </span>
              <span>
                <label>Zip Code</label>
                <Input
                  required
                  value={consignerData.zipCode}
                  onChange={(value) =>
                    setConsignerData({
                      ...consignerData,
                      zipCode: value.target.value,
                    })
                  }
                />
              </span>
            </Form>
            <CustomFooter style={{ padding: 8 }}>
              <span style={{ gap: 8 }}>
                <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <Button onClick={() => setCurrentStep(1)}>Back</Button>
                </div>
                <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <Button
                    disabled={
                      consignerData.email === "" ||
                      consignerData.phone === "" ||
                      consignerData.zipCode === "" ||
                      consignerData.firstName === "" ||
                      consignerData.lastName === "" ||
                      dataSubmitting ||
                      location === "" ||
                      !location
                    }
                    type="primary"
                    onClick={handleCompleteProcess}
                  >
                    Done
                  </Button>
                </div>
              </span>
            </CustomFooter>
          </div>
        )}
      </Container>
    );
  return (
    <GridContainer style={{ width: "100%", maxWidth: "100%" }}>
      {/* <h1>Get Paid</h1> */}
      <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
        {stepTwoPage !== 3 ? (
          <ProductItemList>
            <PayHeader>
              <b>Request Price</b>
              <label>Input your price</label>
            </PayHeader>
            {inventoriesToAdd
              .slice(0, 1)
              .map((inventory: Inventory, key: number) => (
                <ProductItemCard
                  // bordered={key === indexItemToEdit}
                  key={key}
                  className={
                    key === indexItemToEdit && stepTwoPage === 1
                      ? "card-selected"
                      : "card"
                  }
                  style={{
                    border:
                      key === indexItemToEdit && stepTwoPage === 1
                        ? "2px solid #685FF9"
                        : "1px solid #DDDDDD99",
                  }}
                  onClick={() => setIndexItemToEdit(key)}
                >
                  <div
                    style={{
                      flex: "0 0 120px",
                      paddingRight: 10,
                      position: "relative",
                    }}
                  >
                    <Image src={inventory?.product?.image} />
                  </div>
                  <div style={{ flex: 3 }}>
                    <label>MODEL</label>
                    <b>{inventory?.product?.title}</b>
                  </div>
                  <div>
                    <label>SKU</label>
                    <b style={{ wordBreak: "break-all" }}>
                      {inventory?.product?.sku}&nbsp;
                    </b>
                  </div>
                  <div>
                    <label>Size</label>
                    <b>{inventory?.option1Value}</b>
                  </div>
                  <div>
                    <label>Price</label>
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory?.price}
                    </b>
                  </div>
                  <div>
                    <label>Quantity</label>
                    <b>{inventory?.quantity}</b>
                  </div>
                  <div style={{ flex: 0 }}>
                    <Button
                      type="text"
                      onClick={() =>
                        setInventoriesToAdd([
                          ...inventoriesToAdd.filter((_, idx) => idx !== key),
                        ])
                      }
                      danger
                      icon={<DeleteOutlined />}
                    />
                  </div>
                </ProductItemCard>
              ))}
            {stepTwoPage === 1 && (
              <div>
                <PriceInput>
                  <div>
                    <h3>Quantity</h3>
                    <div>
                      <InputNumber
                        prefix={null}
                        readOnly={indexItemToEdit < 0}
                        // bordered={false}
                        value={candidateQty}
                        min={1}
                        onChange={(value) => setCandidateQty(value ?? 0)}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <h3>Requested Price</h3>
                    <div className="price-input" style={{ flex: 3 }}>
                      <Input
                        readOnly={indexItemToEdit < 0}
                        bordered={false}
                        prefix={getSymbolFromCurrency(store.currency) || "$"}
                        value={candidatePrice}
                        onChange={(value) =>
                          setCandidatePrice(+value.target.value)
                        }
                      />
                    </div>
                  </div>
                  <ItemNextButton
                    style={{
                      position: "relative",
                      left: "initial",
                      bottom: "initial",
                      transform: "none",
                      maxWidth: "50%",
                      alignSelf: "flex-end",
                    }}
                    // disabled={ /*  || (newInventory && Number(newInventory.price) === 0) */}
                    onClick={() => {
                      if (
                        indexItemToEdit !== inventoriesToAdd.length - 1 ||
                        inventoriesToAdd.some(
                          (inventory) => Number(inventory.price) === 0
                        )
                      ) {
                        setIndexItemToEdit((prev) => prev + 1);
                      } else {
                        setStepTwoPage((prev) => prev + 1);
                      }
                    }}
                  >
                    Next
                  </ItemNextButton>
                </PriceInput>
              </div>
            )}
            {inventoriesToAdd.slice(1).map((inventory, key) => (
              <ProductItemCard
                // bordered={key + 1 === indexItemToEdit}
                className={
                  key + 1 === indexItemToEdit && stepTwoPage === 1
                    ? "card-selected"
                    : "card"
                }
                style={{
                  border:
                    key + 1 === indexItemToEdit && stepTwoPage === 1
                      ? "2px solid #685FF9"
                      : "1px solid #DDDDDD99",
                }}
                key={key}
                onClick={() => setIndexItemToEdit(key + 1)}
              >
                <div
                  style={{
                    flex: "0 0 120px",
                    paddingRight: 10,
                    position: "relative",
                  }}
                >
                  <Image src={inventory?.product?.image} />
                </div>
                <div style={{ flex: 3 }}>
                  <label>MODEL</label>
                  <b>{inventory?.product?.title}</b>
                </div>
                <div>
                  <label>SKU</label>
                  <b style={{ wordBreak: "break-all" }}>
                    {inventory?.product?.sku}&nbsp;
                  </b>
                </div>
                <div>
                  <label>Size</label>
                  <b>{inventory?.option1Value}</b>
                </div>
                <div>
                  <label>Price</label>
                  <b>
                    {getSymbolFromCurrency(store.currency)}
                    {inventory?.price}
                  </b>
                </div>
                <div>
                  <label>Quantity</label>
                  <b>{inventory?.quantity}</b>
                </div>
                <div style={{ flex: 0 }}>
                  <Button
                    type="text"
                    onClick={() =>
                      setInventoriesToAdd([
                        ...inventoriesToAdd.filter((_, idx) => idx !== key),
                      ])
                    }
                    danger
                    icon={<DeleteOutlined />}
                  />
                </div>
              </ProductItemCard>
            ))}
            {newInventory &&
              newInventory.map((newInventoryData, idx: number) => (
                <ProductItemCard key={idx} bordered>
                  <div style={{ flex: "0 0 120px", paddingRight: 10 }}>
                    <Image src={newInventoryData?.product?.image} />
                  </div>
                  <div>
                    <label>MODEL</label>
                    <b>{newInventoryData?.product?.title}</b>
                  </div>
                  <div>
                    <label>SKU</label>
                    <b>{newInventoryData?.product?.sku}&nbsp;</b>
                  </div>
                  <div>
                    <label>Size</label>
                    <b>{newInventoryData?.option1Value}</b>
                  </div>
                  <div>
                    <label>Price</label>
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {newInventoryData?.price}
                    </b>
                  </div>
                  <div>
                    <label>Condition</label>
                    <b>{newInventoryData?.option2Value}</b>
                  </div>
                  <div style={{ flex: 0 }}>
                    <Button
                      type="text"
                      danger
                      onClick={() => setNewInventory([])}
                      icon={<CloseCircleOutlined />}
                    />
                  </div>
                </ProductItemCard>
              ))}
            <Button
              type="text"
              onClick={() => setCurrentStep((prev) => prev - 1)}
              className="add-product-btn"
            >
              + Add Products
            </Button>
          </ProductItemList>
        ) : (
          <LocationSelector>
            <PayHeader>
              <b>Shipping</b>
              <label>Shipping method, please select one.</label>
            </PayHeader>
            <div>
              <span style={{ display: "flex", flexWrap: "nowrap", gap: 16 }}>
                <PayCard
                  className={`${methodDelivery === 1 ? "selected" : ""}`}
                  onClick={() => setMethodDelivery(1)}
                >
                  <Image preview={false} src={PushPinOutlined} />
                  <div>
                    <b>Drop Off</b>
                    <label onClick={() => setShowLocationModal(true)}>
                      {location
                        ? location
                        : "You need to select the drop off location."}
                    </label>
                    <Button
                      disabled={dataLoading}
                      onClick={() => setShowLocationModal(true)}
                    >
                      Select
                    </Button>
                  </div>
                </PayCard>
                <PayCard
                  // className={`${methodDelivery === 2 ? "selected" : ""}`}
                  style={{ cursor: "not-allowed" }}
                  onClick={() => setMethodDelivery(1)}
                >
                  {" "}
                  {/* should be 2 */}
                  <Image preview={false} src={ShopOutlined} />
                  <div>
                    <b>Ship to Store</b>
                    <label>
                      {location
                        ? location
                        : " You have 34 stores, select the closest one to you."}
                    </label>
                    <Button>Select</Button>
                  </div>
                </PayCard>
              </span>
            </div>
          </LocationSelector>
        )}
        <PaymentTab>
          <ButtonGroup
            style={{
              display: "flex",
              // width: "100%",
              justifyContent: "right",
              alignItems: "center",
              gap: 12,
              marginBottom: 50,
            }}
          >
            <Button
              style={{
                // alignSelf: "stretch",
                height: 45,
                // width: 203,
                // maxWidth: "50%",
                padding: "10px 40px",
                // flex: 1,
                borderRadius: 8,
                background: "var(--Grey---Button-Fill, #F4F4F4)",
                color: "#292D32",
                fontWeight: 600,
              }}
              type="default"
              onClick={() => {
                if (stepTwoPage !== 1) {
                  setStepTwoPage((prev) => prev - 1);
                } else {
                  setCurrentStep((prev) => prev - 1);
                }
              }}
            >
              Back
            </Button>
            <StyledNextButton
              style={{
                position: "relative",
                left: "initial",
                bottom: "initial",
                transform: "none",
                // maxWidth: "50%",
                // flex: 1,
                height: 45,
              }}
              // disabled={ /*  || (newInventory && Number(newInventory.price) === 0) */}
              onClick={() => {
                if (
                  indexItemToEdit !== inventoriesToAdd.length - 1 ||
                  inventoriesToAdd.some(
                    (inventory) => Number(inventory.price) === 0
                  )
                ) {
                  setIndexItemToEdit((prev) => prev + 1);
                } else {
                  if (stepTwoPage === 3 && (location === "" || !location)) {
                    message.error("Drop Off location is required.");
                  } else if (stepTwoPage === 3 && location && location !== "") {
                    setCurrentStep((prev) => prev + 1);
                  } else if (stepTwoPage !== 3) {
                    setStepTwoPage((prev) => prev + 1);
                  }
                }
              }}
            >
              Next
            </StyledNextButton>
          </ButtonGroup>
        </PaymentTab>
      </div>
    </GridContainer>
  );
};
